<template>
  <div class="template-1">
    <page-header class="page-header container">
      <h1 @change="page_load()">
        {{ title }}
      </h1>
    </page-header>
    <page-body class="mx-lg">
      <camp-selector class="pb-4" @camp_changed="page_load()" :showCamp="$route.path.split('/')[4] === 'cm'"
        :onlyUSA="onlyUSA" :tooltip="translations.tcCurrentHome" :i18n="translations.components"></camp-selector>
      <section class="section-1">
        <data-table :fields="fields" :listType="list_type" :items="sortedCandidates" :toggleMe="true" :addItems="addItems"
          :handleViewAction="true" :handleEditAction="false" @handleApproveCandidate="handleApproveCandidate"
          @handleDenyCandidate="handleDenyCandidate" @handleViewAction="handleViewAction"></data-table>
        <b-modal id="modal-scoped" ref="my-modal">
          <template #modal-header="">
            <h5>Camp Cabinet Recommendation</h5>
          </template>

          <template #default="">
            <b-form-checkbox id="" checked="true" class="" type="text" style="float: left"> </b-form-checkbox>
            <h5 style="">{{ selectedPresidentLabel() }}</h5>
            <br />
            <h5>Notes:</h5>
            <textarea name="" id="" cols="60" rows="10" v-model="selectedCandidate.cmi_notes"></textarea>
          </template>

          <template #modal-footer="{ cancel }">
            <b-button size="sm" variant="primary" @click="cancel()"> Cancel </b-button>
            <!-- Emulate built in modal footer ok and cancel button actions -->
            <b-button size="sm" variant="primary" @click="approveCandidateAction()" v-if="selectedAction == 'approve'">
              Recommend Candidate
            </b-button>
            <b-button size="sm" variant="primary" @click="denyCandidateAction()" v-if="selectedAction == 'deny'">
              Not Recommended
            </b-button>
            <!-- Button with custom close trigger value -->
          </template>
        </b-modal>
        <modal name="ReportWindow" :draggable="true" :resizable="true" :adaptive="true" width="100%" height="100%"
          :scrollable="true">
          <div class="ReportWindowheader" id="ReportWindowheader">
            <b-button class="ReportWindowClose" size="sm" variant="primary" @click="ReportWindowClose()">
              Close
            </b-button>
          </div>
          <div>
            <DMDV />
          </div>
        </modal>
      </section>
    </page-body>
    <page-footer></page-footer>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import CampSelect from '@/components/CampSelect.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import DataTable from '@/components/AuxiliaryDataTableDigitalMembers.vue'
import DMDV from '@/views/officers/camp/DigitalMemberDetailsView.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'validate-membership-applications-cm',
  mixins: [translationMixin],
  data() {
    return {
      translations: {},
      title: '',
      onlyUSA: true,
      selectedCandidate: {},
      selectedAction: '',
      camp_state_source: '',
      list_type: 'validate_membership_applications',
      fields: [
        { key: 'cad_name', label: 'Name', sortable: true },
        { key: 'spouse', label: '', sortable: false },
        { key: 'cad_add_date', label: 'Date', sortable: true },
        { key: 'eml_address', label: 'Email', sortable: true },
        { key: 'sponsor', label: 'Sponsor', sortable: true },
        { key: 'yes', label: 'Yes', sortable: false },
        { key: 'no', label: 'No', sortable: false },
        { key: 'actions', label: 'View', sortable: false },
      ],
      hiddenItems: {
        display: false,
      },
      addItems: {
        display: false,
      },
    }
  },
  components: {
    campSelector: CampSelect,
    dataTable: DataTable,
    DMDV: DMDV,
    pageBody: pageBody,
    pageFooter: pageFooter,
    pageHeader: pageHeader,
  },
  methods: {
    ...mapActions({
      approve: 'digitalCandidateMember/approveCandidate',
      deny: 'digitalCandidateMember/denyCandidate',
      getCampMembersChurchesUrl: 'churchMinistry/getCampMembersChurchesUrl',
      getCandidates: 'digitalCandidateMember/getCandidates',
      getMemberChurches: 'campModule/getMemberChurches',
      loadChurches: 'churchMinistry/loadChurches',
      setLoadingStatus: 'menu/setLoadingStatus',
      setMemberCandidate: 'digitalCandidateMember/setMemberCandidate',
      setSelectedIndividualKey: 'user/setSelectedIndividualKey',
    }),
    createTitle(code) {
      return {
        st: this.translations.tcStateValidateMembershipApplications,
        cm: this.translations.tcCampValidateMembershipApplications,
      }[code]
    },
    selectedPresidentLabel() {
      return `${this.selectedCandidate.cmi_president_firstname} ${this.selectedCandidate.cmi_president_lastname} - Camp President`
    },
    async campStateSource() {
      await (async () => {
        this.camp_state_source = `${process.env.VUE_APP_MEMBERSHIP_VALIDATION}/candidate/validation?sValue=${this.userCampKey}|${this.userId}`
      })()
    },
    addPresenter() {
      this.$router.push('/')
    },
    handleEditClick(data) {
      this.setSelectedIndividualKey(data.item.ind_key)
      this.$router.push({ name: 'individual-details-edit' })
    },
    setModalParams(action, candidate) {
      this.selectedCandidate = candidate
      this.selectedAction = action
    },
    handleApproveCandidate(candidate) {
      this.setModalParams('approve', candidate)
      this.$refs['my-modal'].show()
    },
    handleDenyCandidate(candidate) {
      this.setModalParams('deny', candidate)
      this.$refs['my-modal'].show()
    },
    async approveCandidateAction() {
      await this.setLoadingStatus(true)
      if (this.selectedCandidate.wife != null) {
        await this.setLoadingStatus(false)
        let result = await this.DialogAlert('approve')
        if (!result) {
          return
        }
      }
      this.$refs['my-modal'].hide()
      await this.setLoadingStatus(true)
      if (this.selectedCandidate.wife != null) {
        let wifeResult = await this.approve(this.selectedCandidate.wife)
        if (!wifeResult) {
          await this.setLoadingStatus(false)
          console.error('error in changing candidate status')
          this.$swal({
            icon: 'error',
            text: 'Could not Approve Wife',
          })
          return
        }
      }

      let result = await this.approve(this.selectedCandidate)
      if (result) {
        await this.page_load()
      } else {
        console.error('error in changing candidate status')
        this.$swal({
          icon: 'error',
          text: 'Could not Approve Candidate',
        })
      }
      await this.setLoadingStatus(false)
    },
    async DialogAlert(actionName) {
      return this.$swal({
        title: `You are about to ${actionName} the wife and the husband`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        reverseButtons: true,
        text: '',
      }).then(async (r) => {
        if (r.value) {
          return true
        } else if (!r.value) {
          return false
        }
      })
    },
    async denyCandidateAction() {
      await this.setLoadingStatus(true)
      if (this.selectedCandidate.wife != null) {
        await this.setLoadingStatus(false)
        let result = await this.DialogAlert('deny')
        if (!result) {
          return
        }
      }
      this.$refs['my-modal'].hide()
      await this.setLoadingStatus(true)
      if (this.selectedCandidate.wife != null) {
        let wifeResult = await this.deny(this.selectedCandidate.wife)
        if (!wifeResult) {
          await this.setLoadingStatus(false)
          console.error('error in changing candidate status')
          this.$swal({
            icon: 'error',
            text: 'Could not Deny Wife',
          })
          return
        }
      }
      let result = await this.deny(this.selectedCandidate)
      if (result) {
        await this.page_load()
      } else {
        this.$swal({
          icon: 'error',
          text: 'Could not Deny Candidate',
        })
      }
      await this.setLoadingStatus(false)
    },
    handleViewAction(candidate) {
      this.setMemberCandidate(candidate.cad_key)
      this.$modal.show('ReportWindow')
    },
    loadTranslations() {
      this.addItems.text = this.translations.tcAddVisit
      const { tcActions, tcChurchName, tcName } = this.translations.components['data-table-member-churches']
      this.fields[0].label = tcName
      this.fields[1].label = tcChurchName
      this.fields[2].label = tcActions
    },
    async page_load() {
      try {
        this.setLoadingStatus(true)
        await Promise.all([
          this.getCandidates(),
          this.getCampMembersChurchesUrl(),
          this.getViewTranslations(),
          this.getComponentTranslations('camp-select', 'data-table-member-churches', 'security-banner'),
        ]).then((results) => {
          const componentTranslations = results[3]
          this.$set(this.translations, 'components', componentTranslations)
          this.loadTranslations()
        })
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    load_finished() {
      this.setLoadingStatus(false)
    },
    loadTranslations() {
      const fourth = this.$route.path.split('/')[4]
      this.title = this.createTitle(!!fourth && fourth !== 'menu' ? fourth : 'cm')
    },
    async get_translations() {
      await Promise.all([
        this.setLoadingStatus(true),
        this.getViewTranslations(),
        this.getComponentTranslations('camp-select'),
      ]).then((results) => {
        const componentTranslations = results[2]
        this.$set(this.translations, 'components', componentTranslations)
        this.loadTranslations()
        this.load_finished()
      })
    },
    //Report Window Tracking
    ReportWindowClose() {
      this.$modal.hide('ReportWindow')
    },
    // End Window
  },
  computed: {
    ...mapGetters({
      candidates: 'digitalCandidateMember/candidates',
      memberChurches: 'campModule/campMemberChurches',
      officerToolbarSelected: 'user/officerToolbarSelected',
      prefCulture: 'user/userPreferredCulture',
      userCampKey: 'user/userCampKey',
      userId: 'user/userId',
      userLanguageKey: 'user/userLanguageKey',
      userOrgKey: 'user/userCampKey',
      userStateKey: 'user/userStateKey',
    }),
    sortedCandidates() {
      let wifeCadKeys = []
      let candidates = this.candidates.map(candidate => {
        let result = this.candidates.filter(cad => cad.cax_husband_gideon_cad_key == candidate.cad_key)
        if (result.length > 0) {
          candidate['wife'] = result[0]
          wifeCadKeys.push(result[0].cad_key)
        } else {
          candidate['wife'] = null
        }
        return candidate
      })
      return candidates.filter(cad => wifeCadKeys.indexOf(cad.cad_key) === -1)
    }
  },
  async created() {
    this.get_translations()
    this.page_load()
  },
}
</script>

<style lang="scss" >
//Report
.v--modal {
  overflow-y: scroll !important;
}

.ReportWindowClose {
  font-weight: bold;
}

.ReportWindow {
  background-color: #f1f1f1;
}

.ReportWindowheader {
  position: absolute;
  padding: 10px;
  z-index: 10;
  color: #fff;
  text-align: right;
  width: 100%;
}
</style>
